import { createBrowserHistory } from 'history';
import { ReactText } from 'react';
import { accessForAdminHeadShed, accessForSSRRSR, getUserRole } from './common-methods';
import { getCountryCodeFromRoute } from './utils/countryUtils';
export const history = createBrowserHistory<any>();
export enum GrowerProfileSlug {
  CORPORATE = 'corporate',
  SEASON = 'season',
  BANK = 'bank-details',
}

export enum GrowerInfoSlug {
  SUMMARY = 'summary',
  PURCHASES = 'purchases',
  DOCUMENTS = 'documents',
  STATISTICAL = 'statistical',
  GRN = 'grn',
  TIN = 'tin',
  AGREEMENT = 'agreement',
}

export const RoutePaths = {
  HOME: () => getCountryCodeFromRoute(),
  LOGIN: () => getCountryCodeFromRoute() + 'login',
  SIGNUP: () => getCountryCodeFromRoute() + 'signup',
  RECOVERY_PASSWORD: () => getCountryCodeFromRoute() + 'recovery-password',
  DASHBOARD: () => getCountryCodeFromRoute() + 'app',
  HELP_AND_SUPPORT: () => `${getCountryCodeFromRoute()}app/help-and-support`,
  ENROLL_NOW: () => getCountryCodeFromRoute() + 'app/enroll-program',
  GROWTHPROGRAM: (slug = ':slug') => `${getCountryCodeFromRoute()}app/growth-program/${slug}`,
  AGROBONUSPROGRAM: (slug = ':slug') => `${getCountryCodeFromRoute()}app/agrobonus-program/${slug}`,
  PRODUCTS_PAGE: () => getCountryCodeFromRoute() + 'app/products',
  USERS_PAGE: (sub = ':registered') => `${getCountryCodeFromRoute()}app/users/${sub}`,
  PENDING_USERS_PAGE: (sub = ':pending-users') => `${getCountryCodeFromRoute()}app/users/${sub}`,
  UPLOAD_GRN: () => getCountryCodeFromRoute() + 'app/upload-grn',
  GRN_HISTORY: () => getCountryCodeFromRoute() + 'app/grn-history',
  GRN_HISTORY_DETAILS: () => getCountryCodeFromRoute() + 'app/grn-history-details',
  GRN_APPROVED: () => getCountryCodeFromRoute() + 'app/grn-approved',
  GRN_SUBMITTED: () => getCountryCodeFromRoute() + 'app/grn-submitted',
  GRN_BONIUS_AGGREEMENT: () => getCountryCodeFromRoute() + 'app/grn-bonus-aggreement',
  GRN_SUCCESS: () => getCountryCodeFromRoute() + 'app/grn-success',
  FIX_PRICE: () => getCountryCodeFromRoute() + 'app/fix-price',
  CLOSED_CAMPAIGN_PAGE: () => getCountryCodeFromRoute() + 'app/campaign-closed',
  /* BONUS_SETTLEMENT_OPTION: () => getCountryCodeFromRoute() + 'app/fix-price/bonus-settlement-option',
  CLASSIC: () => getCountryCodeFromRoute() + 'app/classic',
  INTERVALLIC: () => getCountryCodeFromRoute() + 'app/intervallic', */
  FIX_PRICE_BONUSSETTLEMENT: () => getCountryCodeFromRoute() + 'app/fix-price-bonussettlement',
  FIX_PRICE_APPROVED: () => getCountryCodeFromRoute() + 'app/fix-price-approved',
  FIX_PRICE_ADDENDUMRESULT: () => getCountryCodeFromRoute() + 'app/fix-price-addendumresult',
  FIX_PRICE_ADDENDUM: () => getCountryCodeFromRoute() + 'app/fix-price-addendum',
  FIX_PRICE_ADDENDUM2: () => getCountryCodeFromRoute() + 'app/fix-price-addendum2',
  FIX_PRICE_SUBMITTED: () => getCountryCodeFromRoute() + 'app/fix-price-submitted',
  GROWER_PROFILE: (info = ':info') => `${getCountryCodeFromRoute()}app/grower-profile/${info}`,
  GROWER_ACCOUNT_VALIDATION: (seasonId = '') =>
    getCountryCodeFromRoute() + `app/grower-account-validation/${seasonId}`,
  MY_CAMPAIGN: (selectedCampaign = ':selectedCampaign') =>
    getCountryCodeFromRoute()?.includes('us')
      ? `${getCountryCodeFromRoute()}app/my-campaign/${selectedCampaign}`
      : `${getCountryCodeFromRoute()}app/my-campaign`,
  GROWER_CORPORATE_PROFILE: () =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.CORPORATE}`,
  GROWER_SEASON_PROFILE: (info = ':info', seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${
      GrowerProfileSlug.SEASON
    }/${seasonId}/${info}`,
  GROWER_BANK_DETAILS: () =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.BANK}`,
  GROWER_SEASON_SUMMARY_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.SUMMARY
    }`,
  GROWER_SEASON_STATISTICAL_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.STATISTICAL
    }`,
  GROWER_SEASON_PURCHASES_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.PURCHASES
    }`,
  GROWER_SEASON_DOCUMENTS_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.DOCUMENTS
    }`,
  GROWER_SEASON_GRN_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.GRN
    }`,
  GROWER_SEASON_TIN_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.TIN
    }`,
  GROWER_SEASON_AGREEMENT_PROFILE: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}app/grower-profile/${GrowerProfileSlug.SEASON}/${seasonId}/${
      GrowerInfoSlug.AGREEMENT
    }`,
  ASK_EXPERT: () => `${getCountryCodeFromRoute()}app/ask-expert`,
  GROWER_FEEDBACK: () => getCountryCodeFromRoute() + 'app/feedback/submit',
  CAMPAIGNS: () => getCountryCodeFromRoute() + 'app/campaigns-selection',
  CAMPAIGNS_RESULT: (seasonId = '') =>
    getCountryCodeFromRoute() + `app/campaigns/result/${seasonId}`,
  LANDING_SCREEN: () => getCountryCodeFromRoute() + 'app/landingscreen',
  UPLOAD_INVOICE_SCREEN: (seasonId = '') =>
    getCountryCodeFromRoute() + `app/uploadInvoiceScreen/${seasonId}`,
  AGREEMENT_SUCCESS: () => getCountryCodeFromRoute() + 'app/campaigns/agreement-success',
  FAQ: () => getCountryCodeFromRoute() + 'app/faq',
  NEWS: () => `${getCountryCodeFromRoute()}app/news`,
  NEWS_DETAILS: (newsId = ':newsId') => `${getCountryCodeFromRoute()}app/news-details/${newsId}`,
  CONFIRM_PASSWORD: (loggedInEmail, initialSignIn) =>
    getCountryCodeFromRoute() +
    `confirmSignup?email=${loggedInEmail}&initialSignIn=${initialSignIn}`,
  COMING_SOON_PAGE: () => `${getCountryCodeFromRoute()}retailer-view`,
};
export enum AdminGrowerInfoSlug {
  DETAILS = 'details',
  BANK = 'bank',
  TIN = 'tin',
  DOCUMENTS = 'documents',
  PRICEFIXATION = 'pricefixation',
  PAYOUT = 'payout',
  GROUPDETAILS = 'groupDetails',
  GRNSTATUS = 'grn_status',
  MULTIGRNSTATUS = 'multi_grn_status',
  COMPANYDETAILS = 'companydetails',
  RETAILERDETAILS = 'retailerdetails',
  COMMITTEDPURCHASES = 'committedpurchases',
  ACTUALPURCHASES = 'actualpurchases',
  PRICEFIX = 'pricefix',
  BANKDETAILS = 'bankdetails',
}

export enum SuperAdminCountryConfiguration {
  BASICDETAILS = 'basicdetails',
  RETAILER = 'retailer',
  PAYMENT = 'payment',
  SUPPORT = 'support',
  OTHERS = 'others',
}
export enum SuperAdminConfiguration {
  COUNTRYSELECTION = 'countryselection',
  COUNTRYSETTINGS = 'countrysettings',
  CAMPAIGNSPECIFICOFFER = 'campaignspecificoffer',
  CAMPAIGNSPECIFICINDEX = 'campaignspecificindex',
}
export const AdminRoutePaths = {
  ADMIN_DASHBOARD: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/dashboard`,
  // MY_CAMPAIGN: (selectedCampaign = ':selectedCampaign') => `${getCountryCodeFromRoute()}admin/${selectedCampaign}/my-campaign`,
  ADMIN_CONSOLE: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/console`,
  ADMIN_CONSOLE_RU: (selectedCampaign = ':selectedCampaign', userEmail = ':userEmail') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/console?email=${userEmail}`,
  ADMIN_SETTINGS: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/settings`,
  ADMIN_SFDC: () => getCountryCodeFromRoute() + `${getUserRole(accessForAdminHeadShed)}/sfdc`,
  NO_CAMPAIGNS_FOUND: () =>
    getCountryCodeFromRoute() + `${getUserRole(accessForAdminHeadShed)}/noCampaignFound`,
  ADMIN_GROWER_INFO: (
    growerId = ':growerId',
    info = ':info',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${info}`,
  ADMIN_GROWER_DETAILS: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.DETAILS}`,
  ADMIN_GROWER_GROUP_DETAILS: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.GROUPDETAILS}`,
  ADMIN_GROWER_GROUP_GRN_STATUS: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.MULTIGRNSTATUS}`,
  ADMIN_GROWER_BANK: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.BANK}`,
  ADMIN_GROWER_TIN: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.TIN}`,
  ADMIN_GROWER_DOCUMENTS: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.DOCUMENTS}`,
  ADMIN_GROWER_PRICE: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.PRICEFIXATION}`,
  ADMIN_GRN_STATUS: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.GRNSTATUS}`,
  ADMIN_GROWER_PAYOUT: (growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/grower/${growerId}/${AdminGrowerInfoSlug.PAYOUT}`,
  ADMIN_GROWER_PRICE_FIXATION: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/growerPriceFixation`,
  ADMIN_GROWER_PRICE_FIXATION_DASHBOARD: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/growerPriceFixation/priceFix`,
  ADMIN_GROWER_PRICE_FIXATION_DETAILS: (selectedCampaign = ':selectedCampaign', requestId = '') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/growerPriceFixation/priceFix/details/${requestId}`,
  ADMIN_GROWER_PRICE_FIXATION_UPLOAD_DOCUMENTS: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/growerPriceFixation/uploadDocuments`,
  ADMIN_GROWER_PRICE_FIXATION_UPLOAD_DOCUMENTS_DETAILS: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/growerPriceFixation/uploadDocuments/details`,
  ADMIN_GROWER_PAYOUT_ASSESSMENT: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/payoutAssessment`,
  ADMIN_GROWER_FINAL_PAYOUT_ASSESSMENT: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/finalPayoutAssessment`,
  ADMIN_FEEDBACK_LIST: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForAdminHeadShed
    )}/${selectedCampaign}/feedback/list`,
  ADMIN_UPLOAD: () => `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/upload`,
  NEWS: () => `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/news`,
  NEWS_DETAILS: (newsId = ':newsId') =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/news-details/${newsId}`,
  ADMIN_ADD_RETAILER: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/user-management`,
  COMING_SOON_PAGE: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/retailer-view`,
  ADMIN_GROWER_REGISTRATION: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForAdminHeadShed)}/grower-enrollment`,
};

export const SuperAdminRoutePaths = {
  SUPERADMIN_CONFIG_SCREEN: () => '/superadmin/configScreen',
  SUPERADMIN_CREATE_NEW_CAMPAIGN: (info = ':info') =>
    `/superadmin/configScreen/createNewCampaign/${info}`,
  SUPERADMIN_EDIT_VIEW_CAMPAIGN: () => `/superadmin/configScreen/editViewCampaign`,
  SUPERADMIN_COUNTRY_SELECTION: () =>
    `/superadmin/configScreen/createNewCampaign/${SuperAdminConfiguration.COUNTRYSELECTION}`,
  SUPERADMIN_COUNTRY_SETTINGS: () =>
    `/superadmin/configScreen/createNewCampaign/${SuperAdminConfiguration.COUNTRYSETTINGS}`,
  SUPERADMIN_CAMPAIN_SPECIFIC_OFFER: () =>
    `/superadmin/configScreen/createNewCampaign/${SuperAdminConfiguration.CAMPAIGNSPECIFICOFFER}`,
  SUPERADMIN_CAMPAIN_SPECIFIC_INDEX: () =>
    `/superadmin/configScreen/createNewCampaign/${SuperAdminConfiguration.CAMPAIGNSPECIFICINDEX}`,
  SUPERADMIN_SETTINGS: () => '/superadmin/countrySpecific/settings',
  SUPERADMIN_BRANDS_PRODUCTS: () => '/superadmin/brandProducts',
  SUPERADMIN_RELEVANT_PRICE_CONFIGURATION: () =>
    '/superadmin/countrySpecific/relevant-price-configuration',
  SUPERADMIN_COUNTRY_ONBOARDING_SCREEN: () => '/superadmin/country/configScreen',
  SUPERADMIN_CREATE_NEW_COUNTRY: (info = ':info') =>
    `/superadmin/country/configScreen/createNewCountry/${info}`,
  SUPERADMIN_COUNTRY_CREATION: () =>
    `/superadmin/country/configScreen/createNewCountry/${SuperAdminCountryConfiguration.BASICDETAILS}`,
  SUPERADMIN_COUNTRY_RETAILER: () =>
    `/superadmin/country/configScreen/createNewCountry/${SuperAdminCountryConfiguration.RETAILER}`,
  SUPERADMIN_COUNTRY_PAYMENT: () =>
    `/superadmin/country/configScreen/createNewCountry/${SuperAdminCountryConfiguration.PAYMENT}`,
  SUPERADMIN_COUNTRY_SUPPORT: () =>
    `/superadmin/country/configScreen/createNewCountry/${SuperAdminCountryConfiguration.SUPPORT}`,
  SUPERADMIN_EDIT_VIEW_COUNTRY: () => `/superadmin/country/configScreen/editViewCountry`,
  SUPERADMIN_COUNTRY_OTHERS: () =>
    `/superadmin/country/configScreen/createNewCountry/${SuperAdminCountryConfiguration.OTHERS}`,
};

export const SalesRepresentativeRoutePaths = {
  SSR_DASHBOARD: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/${selectedCampaign}/dashboard`,
  SSR_CONSOLE: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/${selectedCampaign}/console`,
  SSR_GROWER_INFO: (
    growerId = ':growerId',
    info = ':info',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForSSRRSR
    )}/${selectedCampaign}/grower/${growerId}/${info}`,
  NEWS: () => `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/news`,
  NEWS_DETAILS: (newsId = ':newsId') =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/news-details/${newsId}`,
  PRE_REGISTER_GROWER: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/grower/pre-regiser`,
  PRE_REGISTER_GROWER_SELECT_SEASON: (context: string) =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/grower/pre-register/selectSeason`,
  PRE_REGISTER_GROWER_SET_SEASON_DETAILS: (seasonId = ':seasonId') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForSSRRSR
    )}/grower/pre-register/season/${seasonId}`,
  NO_CAMPAIGNS_FOUND: () =>
    `${getCountryCodeFromRoute()}${getUserRole(accessForSSRRSR)}/noCampaignFound`,
  SSR_GROWER_PAYOUT_ASSESSMENT: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForSSRRSR
    )}/${selectedCampaign}/payoutAssessment`,
  SSR_GROWER_FINAL_PAYOUT_ASSESSMENT: (selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${getUserRole(
      accessForSSRRSR
    )}/${selectedCampaign}/finalPayoutAssessment`,
};

export const CommonRoutePaths = {
  GROWER_INFO: (
    userRole,
    growerId = ':growerId',
    info = ':info',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${info}`,
  GROWER_COMPANY_DETAILS: (
    userRole,
    growerId = ':growerId',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.COMPANYDETAILS
    }`,
  GROWER_RETAILER_DETAILS: (
    userRole,
    growerId = ':growerId',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.RETAILERDETAILS
    }`,
  GROWER_COMMITTED_PURCHASES: (
    userRole,
    growerId = ':growerId',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.COMMITTEDPURCHASES
    }`,
  GROWER_ACTUAL_PURCHASES: (
    userRole,
    growerId = ':growerId',
    selectedCampaign = ':selectedCampaign'
  ) =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.ACTUALPURCHASES
    }`,
  GROWER_PRICE_FIX: (userRole, growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.PRICEFIX
    }`,
  GROWER_PAYOUT: (userRole, growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.PAYOUT
    }`,
  GROWER_DOCUMENTS: (userRole, growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.DOCUMENTS
    }`,
  GROWER_BANK_DETAILS: (userRole, growerId = ':growerId', selectedCampaign = ':selectedCampaign') =>
    `${getCountryCodeFromRoute()}${userRole?.toLowerCase()}/${selectedCampaign}/grower/${growerId}/${
      AdminGrowerInfoSlug.BANKDETAILS
    }`,
};

export const navigateTo = (targetPath: ReactText): void => {
  history.push(targetPath.toString());
};

export const PublicRoutes = [RoutePaths.HOME()];
