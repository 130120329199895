import { CountryConfigKeys } from 'core/constants';
const baseUrl = process.env.REACT_APP_API_URL;
export const API = {
  searchGrower: (type: string, query: string) => `${baseUrl}/v2/user/search/${type}/${query}`,
  first_login: `${baseUrl}/v2/user/first-login`,
  resendVerifyLink: `${baseUrl}/v2/user/resend-verify-link`,
  notification: baseUrl + '/notification',
  markNotificationAsRead: (notificationId: string) =>
    `${baseUrl}/notification/${notificationId}/read`,
  authorization_features: `${baseUrl}/authorization-features`,
  grower_season_enrollment: `${baseUrl}/v2/growers/`,
  tax_detail: baseUrl + '/tax/verification',
  modal_detail: baseUrl + '/country',
  validate_phone_signup: baseUrl + '/validate/',
  file_upload: baseUrl + '/get-presigned-url',
  grower_purchase_points: baseUrl + '/grower-purchases-and-points/',
  hedging_protection_level: baseUrl + '/update-request/',
  hedging_protection_details: baseUrl + '/program',
  post_tin_details: (growerId, cropYear) =>
    `${baseUrl}/v2/company-details/grower/${growerId}?cropYear=${cropYear}`,
  bank_details: baseUrl + '/bank-details/',
  post_bank_details: (growerId, cropYear) =>
    `${baseUrl}/v2/bank-details/grower/${growerId}?cropYear=${cropYear}`,
  previous_bank_details: (growerId, requestId) =>
    `${baseUrl}/previous-bank-details/${growerId}?requestId=${requestId}`,
  authorization_rights: (requestId) => `${baseUrl}/request/${requestId}/authorization-rights`,
  post_authorization_rights: (growerId, cropYear) =>
    `${baseUrl}/v2/authorization-rights/grower/${growerId}?cropYear=${cropYear}`,
  upload_grn_attachments: baseUrl + '/attachment-details/',
  upload_customer_invoice: baseUrl + '/invoice/grower/',
  review_customer_invoice: baseUrl + '/review-invoice/',
  update_invoice: baseUrl + '/v2/invoices/',
  delete_customer_invoice: baseUrl + '/invoice/',
  approve_grn_file: baseUrl + '/approve-grn-file',
  get_customer_invoices: baseUrl + '/invoices/grower/',
  upload_file_url: baseUrl + '/v2/system-document/upload/url',
  contracts: baseUrl + '/v2/contracts',
  get_download_url: baseUrl + '/get-download-url/',
  get_current_stage: baseUrl + '/get-current-stage',
  get_company_detail: baseUrl + '/get-company-details/',
  previous_company_detail: baseUrl + '/previous-company-details/',
  update_current_stage_and_status: baseUrl + '/update-request/',
  update_current_stage_and_status_v2: (growerId, cropYear) =>
    `${baseUrl}/v2/requests/grower/${growerId}?cropYear=${cropYear}`,
  contract_aggrement_pdf: baseUrl + '/download-bonus-agreement/',
  contract_aggrement_content: baseUrl + '/get-contract-agreement-text/',
  fix_price: baseUrl + '/update-request/',
  grower_bank_details: baseUrl + '/grower-bank-details/',
  comments_details: baseUrl + '/comments',
  v2_requests: baseUrl + '/v2/requests/',
  admin_console: {
    getReasons: (reasonType: string) => baseUrl + `/v2/request-reject/reasons/${reasonType}`,
    rejectCustomer: baseUrl + '/v2/request-reject/',
    reset_password: (id: string) => baseUrl + '/password/' + id,
    get_All_growers: baseUrl + '/growers',
    get_MultiTin_growers: baseUrl + '/legalentity',
    validateGroupname: baseUrl + '/legalentity/validate',
    submitMutliTIN: baseUrl + '/legalentity',
    submitSFDCFileUpload: baseUrl + '/sfdcData',
    get_sfdc_upload_logs: baseUrl + '/sfdcData',
    get_grn_upload_logs: (type, year) => `${baseUrl}/grn-attachment-details/${type}/${year}`,
    submit_grn_upload_logs: (type, year) => `${baseUrl}/grn-attachment-details/${type}/${year}`,
    updateLocales: baseUrl + '/admin/locale',
    get_grower_details: (growerId, campaignId) =>
      `${baseUrl}/growers/${growerId}/season/${campaignId}`,
    get_us_grower_details: (growerId, campaignId) =>
      `${baseUrl}/grower/${growerId}/season/${campaignId}`,
    get_request_status: baseUrl + '/get-request-status',
    approval: baseUrl + '/admin-approval',
    grn_bonus_admin_approval: baseUrl + '/legalentity/admin-approval',
    get_all_grower_price_fixing: baseUrl + '/price-fix-requests/growers',
    get_priceFixation_execlFile: baseUrl + '/price-fix-requests/excel-download',
    get_download_classic_intervallic: baseUrl + '/price-fix-requests/categorized/index',
    get_bank: { prefix: baseUrl + '/countries/', postfix: '/bank' },
    get_download_send_bank_notification: baseUrl + '/price-fix-requests/download/index',
    get_price_fixing_stage: baseUrl + '/price-fix-requests/stage/index',
    get_fix_price_card_info: baseUrl + '/price-fix-requests/fix-price',
    get_fix_price_card_info_US: baseUrl + '/price-fix-v2/premium',
    get_bank_details_for_premiums: baseUrl + '/bank-details',
    get_premium_approver: {
      prefix: baseUrl + '/countries/',
      postfix: '/approvers',
    },
    get_price_fix_details: baseUrl + '/price-fix-request/',
    get_price_fix_details_v2: baseUrl + '/price-fix-v2/growers',
    get_currency_details: baseUrl + '/currency-pair/exchange-rate',
    get_fix_price_card_info_for_upload: baseUrl + '/price-fix-requests/upload',
    post_upload_document_complete_req: baseUrl + '/price-fix-requests/upload',
    get_price_fix_statistics: baseUrl + '/price-fix-requests/statistics',
    get_payout_details: baseUrl + '/payout-request/',
    get_price_fix_documents: baseUrl + '/get-price-fix-attachment-details',
    get_current_season: baseUrl + '/season',
    create_grower: `${baseUrl}/grower`,
    get_legalentity_products: (campaignId) => `${baseUrl}/legalentity-products/${campaignId}`,
    get_master_data_legalentity_product: (campaignId) => `${baseUrl}/master-data/${campaignId}`,
    downloadExcel: `${baseUrl}/legalentity-products-excel`,
    getTabData: baseUrl + '/countries',
    getPayoutDetails: baseUrl + '/lock-in-price',
    get_all_payout_V2: baseUrl + '/price-fix-v2/payout',
    get_all_final_payout_V2: baseUrl + '/price-fix-v2/final-payout',
    adminUpdateGrowerStatus: (growerId) => `${baseUrl}/admin/update-grower/${growerId}`,
    master_excel_data: `${baseUrl}/master-data/download`,
    get_dashboard_tab_data: `${baseUrl}/reports`,
    get_retialers: (retailerId, retailerType) =>
      `${baseUrl}/v2/user/${retailerType}/retailer/${retailerId}`,
    delete_retailers: (userId) => `${baseUrl}/v2/user/${userId}`,
    update_retailers: (userId) => `${baseUrl}/v2/user/${userId}`,
  },
  grn_history: {
    attachment: baseUrl + '/attachment-details/',
    grn_amount: (requestId) => `${baseUrl}/grn-amount/${requestId}`,
    multiple_grn_amount: (groupId) => `${baseUrl}/legalentity/grn-amount/group/${groupId}`,
    multiple_tin: (seasonId, groupId) =>
      `${baseUrl}/legalentity/season/${seasonId}/group/${groupId}`,
    distributor_list: (requestId, tinId) =>
      `${baseUrl}/grn-amount/${requestId}/${tinId}/distributors`,
  },
  price_fixation: {
    instrument_Details: baseUrl + '/instrumentDetails',
    instrument_Details_with_request: baseUrl + '/instrumentDetails/request',
    holidays_list: baseUrl + '/v2/holidays',
    price_history_data: baseUrl + '/priceHistory',
    trading_hours: baseUrl + '/tradingHours',
    price_fix_history: baseUrl + '/priceFixHistory/season',
  },
  addendum_agreement_content: baseUrl + '/get-addendum1-text/',
  addendum2_agreement_content: baseUrl + '/get-addendum2-text/',
  addendum_agreement_pdf: baseUrl + '/download-addendum1',
  addendum2_agreement_pdf: baseUrl + '/download-addendum2/',
  updateLocales: baseUrl + '/locale',
  grower_profile: baseUrl + '/profile',
  grower_season: baseUrl + '/growers/',
  payout: {
    get_all_payout: baseUrl + '/payout',
    get_all_payout_execlFile: baseUrl + '/payout/excel-download',
    payout_summary: baseUrl + '/payout/',
    generate_payout: baseUrl + '/payout/generate-addendum2',
    get_all_final_payout: baseUrl + '/final-payout',
    get_all_final_payout_excelFile: baseUrl + '/final-payout/excel-download',
  },
  feedback: {
    get_all_feedback: baseUrl + '/feedback',
    download_feedback: baseUrl + '/feedback/download',
    save_feedback: baseUrl + '/feedback',
  },
  request: {
    post_request: baseUrl + '/request',
  },
  companyDetails: {
    getByUserId: (requestId: string) => `${baseUrl}/user/${requestId}/company-details`,
    update: (requestId: string) => `${baseUrl}/grower/${requestId}/company-details`,
  },
  user: {
    update: (growerId: string) => `${baseUrl}/user/${growerId}`,
  },
  update_ask_expert_status: baseUrl + '/expert-help/',
  get_states_list: (countryCode) => `${baseUrl}/states?country_code=${countryCode}`,
  get_news: (maxRecords) =>
    `${baseUrl}/getNews?maxRecords=${maxRecords}&sources=BRUG&displayType=full&barchartClassification=FUT.GRN,FUT.GRN.CRN,FUT.GRN.WEAT,FUT.GRN.SYBN&images=true`,
  get_news_details: (newsId) =>
    `${baseUrl}/getNews?&storyId=${newsId}&fields=largeImageURL&sources=BRUG&displayType=full&barchartClassification=FUT.GRN,FUT.GRN.CRN,FUT.GRN.WEAT,FUT.GRN.SYBN&images=true`,
  super_admin: {
    get_bank: { prefix: baseUrl + '/countries/', postfix: '/bank' },
    country_list: baseUrl + '/countries',
    admin_list: baseUrl + '/countries',
    approver_list: baseUrl + '/countries',
    crop_list: baseUrl + '/crops',
    sales_area_list: baseUrl + '/sfdc/sales-area',
    commercial_unit_list: baseUrl + '/sfdc/commercial-unit',
    ssr_types: baseUrl + '/sfdc/ssr-types',
    product_list: baseUrl + '/product',
    create_campaign: baseUrl + '/seasons-campaign',
    get_campaign: (countryId: string, seasonId: string) =>
      `${baseUrl}/countries/${countryId}/seasons-campaign/${seasonId}`,
    get_campaigns: (countryId: string, cropYear: string) =>
      `${baseUrl}/countries/${countryId}/seasons-campaign?crop_year=${cropYear}`,
    ssr: baseUrl + '/ssr',
    sfdc: baseUrl + '/sfdc/ssr',
    ssr_list: baseUrl + '/ssrs',
    entity: `${baseUrl}/entity`,
    getBrandProducts: (countryCode: string, retailerId: string, crop_year_id: string) =>
      `${baseUrl}/product?country_code=${countryCode}&retailer_id=${retailerId}&crop_year_id=${crop_year_id}`,
    createBrandProducts: `${baseUrl}/product`,
    deleteBrandProducts: (retailerId: string, cropYear: string) =>
      `${baseUrl}/v2/products/${retailerId}/${cropYear}`,
    downloadExcel: baseUrl + '/download-excel',
    commodity_index: `${baseUrl}/commodity-index`,
    currency_index: `${baseUrl}/currency-index`,
    currency_pair: `${baseUrl}/currency-pair`,
    all_country_list: (type: string) => `${baseUrl}/countries?type=${type}`,
    country_roles: () => `${baseUrl}/v2/roles-features/`,
    onboard_country: baseUrl + '/onboarding/country',
    delete_country: (countryId: string) => baseUrl + `/v2/country/${countryId}`,
    sequence: baseUrl + '/sequence',
    rolesAndFeatures: baseUrl + '/v2/roles-features-country',
    getRolesAndFeatures: (countryId: string) => baseUrl + `/v2/roles-features-country/${countryId}`,
    countryConfigurations: (countryId: string) =>
      baseUrl + `/countries/${countryId}/configurations`,
    getCountryConfiguration: (countryId: string, key: CountryConfigKeys) =>
      baseUrl + `/countries/${countryId}/configurations/${key}`,
    putCountryConfigurations: (countryId: string, configKey: string) =>
      baseUrl + `/countries/${countryId}/configurations/${configKey}`,
    retailerConfigurations: baseUrl + '/retailer',
    getRetailerConfigurations: (countryId: string, type = '') =>
      baseUrl + `/retailer?countryId=${countryId}&type=${type}`,
    getSfdcQueryCatalogues: (countryCode: string) => baseUrl + `/query_catalogues/${countryCode}`,
    postSfdcQueryCatalogues: baseUrl + '/query_catalogues/country',
  },
  salesRepresentativeDashboard: {
    preRegisteredGrower: `${baseUrl}/grower`,
    downloadExcel: `${baseUrl}/growers-products-excel/season`,
    preRegisteredGrowersList: `${baseUrl}/growers-products/season`,
    deleteGrowersList: `${baseUrl}/grower`,
    sfdc_grower_list: `${baseUrl}/sfdc/grower`,
  },
  sequence: {
    getByCountryId: (countryId: string, program: string) =>
      `${baseUrl}/sequence/country/${countryId}/program/${program}`,
  },
  grower_registration_details: `${baseUrl}/grower/me`,
  retailers_list: `${baseUrl}/retailer`,
  send_request_to_admin: {
    get_all_seasons: baseUrl + '/price-fix-requests',
    send_all_seasons: baseUrl + '/price-fix-requests/send-to-admin',
  },
  grower_program_documents: `${baseUrl}/file`,
  grower_existing_seasons: (email) => `${baseUrl}/grower/seasons?email=${email}`,
  update_grower_status: (requestIds) => `${baseUrl}/update-requests?request_ids=${requestIds}`,
  add_conversion: (countryId: string) => `${baseUrl}/countries/${countryId}/conversions`,
  update_conversion: (countryId: string, conversionId: string) =>
    `${baseUrl}/countries/${countryId}/conversions/${conversionId}`,
  delete_conversion: (countryId: string, conversionId: string) =>
    `${baseUrl}/countries/${countryId}/conversions/${conversionId}`,
  get_conversion: (countryId: string) => `${baseUrl}/countries/${countryId}/conversions`,
  unitConversions: {
    get_per_unit_conversion: baseUrl + '/unit-conversions',
  },
  cropYear_list: `${baseUrl}/crop-years`,
  support_details: `${baseUrl}/supportDetails`,
  faq_details: `${baseUrl}/faq`,
  commodity_relevant_prices_list: `${baseUrl}/relevant-price/commodity/change`,
  currency_relevant_prices_list: `${baseUrl}/relevant-price/currency/change`,
  currency_prices_history_data: `${baseUrl}/relevant-price/currency/timeframe`,
  commodity_prices_history_data: `${baseUrl}/relevant-price/commodity/history`,
  time_zone_list: `${baseUrl}/tz?distinct=${true}`,
  finalExchangeRate: {
    addExchangeRate: `${baseUrl}/final-exchange-rate`,
    addExchangeRateBySeasonId: `${baseUrl}/final-exchange-rate`,
  },
  campaign: {
    get_campaign_list: (growerId, countryId, taxId, cropYear) =>
      `${baseUrl}/v2/seasons/crop-year/${cropYear || null}/grower/${
        growerId || null
      }?countryId=${countryId}&tin=${taxId}`,
  },
  validator: (type) => `${baseUrl}/v2/validator/${type}`,

  post_file_upload: (type: string) => `${baseUrl}/v2/files/metadata/type/${type}`,
  get_file_upload: (requestId: string, bucketName: string, docType: string, type: string) =>
    `${baseUrl}/v2/files/metadata/type/${type}?indexCode=${requestId}&bucketName=${bucketName}&searchKeyword=${docType}`,

  delete_file_upload: `${baseUrl}/v2/files/metadata/type`,
  ru_grower_enrollment: `${baseUrl}/v2/user/ru/grower`,
  ru_grower_terms_services: (userId: string) => `${baseUrl}/v2/user/terms-policy-accept/${userId}`,
  accept_terms_services: `${baseUrl}/v2/user/terms-policy-accept`,
  update_agriclime_products: `${baseUrl}/v2/requests/agrilcime-products`,
  updateRetailer: (retailerId: string) => `${baseUrl}/v2/user/retailer/${retailerId}`,
};
